<template>
  <v-data-table
    :headers="headers"
    :items="sites"
    :sort-by="['id']"
    :sort-desc="[false]"
    class="elevation-1"
    :expanded="expanded"
  >
    <template v-slot:item.actions="{ item }">
      <a target="_blank">
        <v-btn class="mx-2" elevation="0" fab small @click="expandRow(item)">
          <v-icon color="primary">
            {{ icons.mdiPencil }}
          </v-icon>
        </v-btn>
      </a>
    </template>
    <template v-slot:expanded-item="{ item }">
      <td :colspan="headers.length">
        <div class="ma-3 mt-5" is="InlineMetrics" :id="item.id"></div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil } from "@mdi/js";
import InlineMetrics from "@/views/pages/admin/report-alert/inline/InlineMetrics.vue";

export default {
  name: "ReportEditDataTable",
  components: {
    InlineMetrics,
  },
  data: () => ({
    headers: [
      { text: "ID", value: "id" },
      { text: "Sites", value: "name" },
      { value: "actions" },
    ],
    expanded: [1],
    icons: {
      mdiPencil,
    },
  }),
  methods: {
    expandRow(item) {
      this.$store.dispatch("alerts/setCurrentId", item.id);
      this.expanded = item === this.expanded[0] ? [] : [item];
    },
  },
  computed: {
    sites() {
      return this.$store.getters["common/getSiteList"];
    },
  },
};
</script>

<style scoped></style>
