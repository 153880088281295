<template>
  <div>
    <v-row class="mt-2">
      <v-col cols="12" sm="2" class="pt-5"> CA </v-col>
      <v-col class="d-flex" cols="12" sm="2">
        <v-icon class="pb-3" color="success"> {{ icons.mdiArrowUp }} </v-icon>
        <v-text-field
          color="success"
          dense
          class="pl-2"
          suffix="%"
          v-model="values.ca.up"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex" cols="12" sm="2">
        <v-icon class="pb-3" color="error"> {{ icons.mdiArrowDown }} </v-icon>
        <v-text-field
          color="error"
          dense
          class="pl-2"
          suffix="%"
          v-model="values.ca.down"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex" cols="12" sm="1"> </v-col>
      <v-col class="d-flex" cols="12" sm="2">
        <v-icon class="pb-3" color="success"> {{ icons.mdiArrowUp }} </v-icon>
        <v-text-field
          color="success"
          dense
          class="pl-2"
          suffix="€"
          v-model="values.ca.upT"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex" cols="12" sm="2">
        <v-icon class="pb-3" color="error"> {{ icons.mdiArrowDown }} </v-icon>
        <v-text-field
          color="error"
          dense
          class="pl-2"
          suffix="€"
          v-model="values.ca.downT"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12" sm="2" class="pt-5"> CA/Platf. </v-col>
      <v-col cols="12" sm="6">
        <v-btn color="" elevation="0" width="100%" outlined @click="openCaModal"
          >Détails par Plateforme</v-btn
        >
      </v-col>
      <v-col cols="12" sm="3">
        <v-btn color="" elevation="0" width="100%" outlined @click="copyCA"
          >Copier le CA global</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="2" class="pt-5"> Sessions </v-col>
      <v-col class="d-flex" cols="12" sm="2">
        <v-icon class="pb-3" color="success"> {{ icons.mdiArrowUp }} </v-icon>
        <v-text-field
          color="success"
          dense
          class="pl-2"
          suffix="%"
          v-model="values.sessions.up"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex" cols="12" sm="2">
        <v-icon class="pb-3" color="error"> {{ icons.mdiArrowDown }} </v-icon>
        <v-text-field
          color="error"
          dense
          class="pl-2"
          suffix="%"
          v-model="values.sessions.down"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex" cols="12" sm="1"> </v-col>
      <v-col class="d-flex" cols="12" sm="2">
        <v-icon class="pb-3" color="success"> {{ icons.mdiArrowUp }} </v-icon>
        <v-text-field
          color="success"
          dense
          class="pl-2"
          v-model="values.sessions.upT"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex" cols="12" sm="2">
        <v-icon class="pb-3" color="error"> {{ icons.mdiArrowDown }} </v-icon>
        <v-text-field
          color="error"
          dense
          class="pl-2"
          v-model="values.sessions.downT"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="2" class="pt-5"> RPM </v-col>
      <v-col class="d-flex" cols="12" sm="2">
        <v-icon class="pb-3" color="success"> {{ icons.mdiArrowUp }} </v-icon>
        <v-text-field
          color="success"
          dense
          class="pl-2"
          suffix="%"
          v-model="values.rpm.up"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex" cols="12" sm="2">
        <v-icon class="pb-3" color="error"> {{ icons.mdiArrowDown }} </v-icon>
        <v-text-field
          color="error"
          dense
          class="pl-2"
          suffix="%"
          v-model="values.rpm.down"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex" cols="12" sm="1"> </v-col>
      <v-col class="d-flex" cols="12" sm="2">
        <v-icon class="pb-3" color="success"> {{ icons.mdiArrowUp }} </v-icon>
        <v-text-field
          color="success"
          dense
          class="pl-2"
          v-model="values.rpm.upT"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex" cols="12" sm="2">
        <v-icon class="pb-3" color="error"> {{ icons.mdiArrowDown }} </v-icon>
        <v-text-field
          color="error"
          dense
          class="pl-2"
          v-model="values.rpm.downT"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12" sm="2" class="pt-5"> RMP/Platf. </v-col>
      <v-col cols="12" sm="6">
        <v-btn
          color=""
          elevation="0"
          width="100%"
          outlined
          @click="openRpmModal"
          >Détails RPM par Plateforme</v-btn
        >
      </v-col>
      <v-col cols="12" sm="3">
        <v-btn color="" elevation="0" width="100%" outlined @click="copyRPM"
          >Copier le RPM global</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiArrowUp, mdiArrowDown, mdiPercent } from "@mdi/js";

export default {
  name: "ConfigMetricsForm",
  props: {
    id: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    values: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        mdiArrowUp,
        mdiArrowDown,
        mdiPercent,
      },
    };
  },
  methods: {
    copyCA() {
      this.$store.dispatch("alerts/copyPlatformValues", "ca");
    },
    copyRPM() {
      this.$store.dispatch("alerts/copyPlatformValues", "rpm");
    },
    openCaModal() {
      this.$store.dispatch("alerts/setCaByPlatformModal", true);
    },
    openRpmModal() {
      this.$store.commit("alerts/setRpmByPlatformModal", true);
    },
  },
  watch: {
    values: {
      handler() {
        this.$store.dispatch("alerts/updateGlobalAlertConfig", {
          id: this.id,
          type: this.type,
          values: this.values,
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
