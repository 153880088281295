<template>
  <div>
    <v-card class="pa-4">
      <p class="text-2xl ma-5 mb-9">
        Modifier les déclencheurs d'alertes par plateforme pour '{{ name }} pour
        des variations sur {{ variations[type] }}.
      </p>

      <v-row class="mt-2" v-for="partner in partners">
        <v-col cols="12" sm="2" class="pt-5">CA-{{ partner.label }}</v-col>
        <v-col class="d-flex" cols="12" sm="2">
          <v-icon class="pb-3" color="success"> {{ icons.mdiArrowUp }} </v-icon>
          <v-text-field
            color="success"
            dense
            class="pl-2"
            suffix="%"
            v-model="values.partners[partner.id].up"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="2">
          <v-icon class="pb-3" color="error"> {{ icons.mdiArrowDown }} </v-icon>
          <v-text-field
            color="error"
            dense
            class="pl-2"
            suffix="%"
            v-model="values.partners[partner.id].down"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="1"> </v-col>
        <v-col class="d-flex" cols="12" sm="2">
          <v-icon class="pb-3" color="success"> {{ icons.mdiArrowUp }} </v-icon>
          <v-text-field
            color="success"
            dense
            class="pl-2"
            suffix="€"
            v-model="values.partners[partner.id].upT"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="2">
          <v-icon class="pb-3" color="error"> {{ icons.mdiArrowDown }} </v-icon>
          <v-text-field
            color="error"
            dense
            class="pl-2"
            suffix="€"
            v-model="values.partners[partner.id].downT"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mx-1 my-3">
        <v-card-text>
          <v-form ref="confirmationForm">
            <v-row>
              <v-col cols="12">
                <v-btn color="primary" class="me-3 mt-3" @click.native="update"
                  >Mettre à jour</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  @click.native="closeModal"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mdiArrowDown, mdiArrowUp, mdiPercent } from "@mdi/js";

export default {
  name: "CaByPlatformModal",
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      variations: {
        d1: "Un jour",
        d7: "7 jours",
        d30: "30 jours",
      },
      icons: {
        mdiArrowUp,
        mdiArrowDown,
        mdiPercent,
      },
    };
  },
  methods: {
    closeModal() {
      this.$store.dispatch("alerts/setCaByPlatformModal", false);
    },
    update() {
      this.closeModal();
    },
  },
  computed: {
    currentId() {
      return this.$store.getters["alerts/getCurrentId"];
    },
    partners() {
      return this.$store.getters["alerts/getPartnersList"];
    },
    globalAlertConfig() {
      return this.$store.getters["alerts/getGlobalAlertConfig"];
    },
    modal() {
      return this.$store.getters["alerts/getCaByPlatformModal"];
    },
    values() {
      return this.$store.getters["alerts/getGlobalAlertConfig"][this.currentId][
        this.type
      ];
    },
  },
  watch: {
    values: {
      handler() {
        this.$store.dispatch("alerts/updateGlobalAlertConfig", {
          id: this.currentId,
          type: this.type,
          values: this.values,
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
