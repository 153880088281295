<template>
  <div>
    <p class="text-2xl mb-6">Créer un rapport d'alertes</p>
    <v-card class="pt-7">
      <v-row class="ma-3">
        <v-col cols="12" sm="8">
          <v-text-field
            v-model="name"
            outlined
            dense
            label="Nom"
            :rules="[required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ma-3">
        <v-col cols="12" sm="8">
          <v-select
            v-model="selectedUsers"
            :items="users"
            item-text="email"
            item-value="id"
            outlined
            multiple
            dense
            label="Utilisateurs"
            :rules="[required]"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="ma-3" v-if="!reportInitialized">
        <v-col cols="12" sm="8">
          <v-select
            v-model="selectedGroupSites"
            :items="siteGroupList"
            item-text="name"
            item-value="id"
            outlined
            multiple
            dense
            label="Univers"
            :rules="[required]"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="ma-3" v-if="!reportInitialized">
        <v-col cols="12" sm="12">
          <inline-metrics :id="-1"> </inline-metrics>
        </v-col>
      </v-row>
      <div v-if="!reportInitialized">
        <v-row class="ma-3">
          <v-col cols="12" sm="12">
            <v-btn color="primary" @click="initReport()"
              >Initialiser le rapport</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <div v-if="reportInitialized">
        <v-row class="ma-3">
          <v-col cols="12" sm="12">
            <report-edit-data-table></report-edit-data-table>
          </v-col>
        </v-row>
      </div>
      <div v-if="reportInitialized">
        <v-row class="ml-3">
          <v-col cols="12" sm="12">
            <v-btn color="primary" @click="saveReport()"
              >Enregistrer le rapport</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-dialog v-model="caByPlatformModal" width="70vw">
      <ca-by-platform-modal
        id="-1"
        :type="$store.getters['alerts/getRangeType']"
        name="Tous les sites"
      ></ca-by-platform-modal>
    </v-dialog>
    <v-dialog v-model="rpmByPlatformModal" width="70vw">
      <rpm-by-platform-modal
        id="-1"
        :type="$store.getters['alerts/getRangeType']"
        name="Tous les sites"
      ></rpm-by-platform-modal>
    </v-dialog>
    <v-dialog v-model="platformsCopyModal" width="70vw">
      <confirm-copy-modal
        :type="$store.getters['alerts/getRangeType']"
      ></confirm-copy-modal>
    </v-dialog>
  </div>
</template>

<script>
import { required } from "@core/utils/validation";
import InlineMetrics from "./inline/InlineMetrics.vue";
import ReportEditDataTable from "@/views/pages/admin/report-alert/components/ReportEditDataTable.vue";
import ConfigMetricsForm from "@/views/pages/admin/report-alert/components/ConfigMetricsForm.vue";
import DelConfirmation from "@/components/common/modals/SiteDelConfirmation.vue";
import CaByPlatformModal from "@/views/pages/admin/report-alert/modals/CaByPlatformModal.vue";
import RpmByPlatformModal from "@/views/pages/admin/report-alert/modals/RpmByPlatformModal.vue";
import axios from "@axios";
import confirmCopyModal from "@/views/pages/admin/report-alert/modals/ConfirmCopyModal.vue";
export default {
  name: "ReportAlertCreate",
  components: {
    CaByPlatformModal,
    RpmByPlatformModal,
    DelConfirmation,
    ConfigMetricsForm,
    ReportEditDataTable,
    InlineMetrics,
    confirmCopyModal,
  },
  async created() {
    await this.$store.dispatch("alerts/getPartnersList");
    await this.$store.dispatch("alerts/setInitialAlertConfig");
    await this.$store.dispatch("alerts/getUsersList");
    this.siteGroupList = await this.$store.dispatch("common/getSiteGroupList");
    await this.$store.dispatch("common/getSiteList");
  },
  data() {
    return {
      siteGroupList: [],
      name: "",
      selectedUsers: [],
      selectedGroupSites: [],
      tab: null,
      reportInitialized: false,
      required,
    };
  },
  methods: {
    async initReport() {
      console.log("init report");
      this.reportInitialized = true;
      await this.$store.dispatch("alerts/initGlobalAlertConfig", {
        name: this.name,
        users: this.selectedUsers,
        groupSites: this.selectedGroupSites,
      });
    },
    async saveReport() {
      await axios.post("/alert-system-report/", {
        name: this.name,
        users: this.selectedUsers.join(","),
        alert_content: JSON.stringify(
          this.$store.getters["alerts/getGlobalAlertConfig"]
        ),
      });
      await this.$store.dispatch("app/setSnackBar", {
        show: true,
        text: `Le rapport ${this.name} a été créé avec succès`,
        color: "success",
        timeout: 5000,
      });
    },
  },
  computed: {
    users() {
      return this.$store.getters["alerts/getUsersList"];
    },
    caByPlatformModal: {
      get() {
        return this.$store.getters["alerts/getCaByPlatformModal"];
      },
      set(value) {
        this.$store.commit("alerts/setCaByPlatformModal", value);
      },
    },
    rpmByPlatformModal: {
      get() {
        return this.$store.getters["alerts/getRpmByPlatformModal"];
      },
      set(value) {
        this.$store.commit("alerts/setRpmByPlatformModal", value);
      },
    },
    platformsCopyModal: {
      get() {
        return this.$store.getters["alerts/getPlatformsCopyModal"];
      },
      set(value) {
        this.$store.commit("alerts/setPlatformsCopyModal", value);
      },
    },
  },
};
</script>

<style scoped></style>
