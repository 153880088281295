<template>
  <div>
    <v-card class="pa-4">
      <p class="text-2xl ma-5 mb-9">
        Attention vous allez écraser les valeurs existantes
      </p>
      <v-row class="mx-1 my-3">
        <v-card-text>
          <v-form ref="confirmationForm">
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="copyValues"
                  >Copier
                </v-btn>
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  @click.native="closeModal"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ConfirmCopyModal",
  methods: {
    copyValues() {
      if (this.$store.getters["alerts/getPlatformsCopyType"] === "ca") {
        this.$store.dispatch("alerts/copyGlobalCA");
      } else {
        this.$store.dispatch("alerts/copyGlobalRPM");
      }
      this.closeModal();
    },
    closeModal() {
      this.$store.dispatch("alerts/setPlatformsCopyModal", false);
    },
  },
};
</script>
