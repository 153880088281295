<template>
  <v-card elevation="1" v-if="globalAlertConfig">
    <v-tabs v-model="tab" background-color="transparent" @change="loadData">
      <v-tab key="d1">J-1</v-tab>
      <v-tab key="d7">J-7</v-tab>
      <v-tab key="d30">J-30</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="d1">
        <v-card-text>
          <config-metrics-form
            :id="id"
            type="d1"
            :values="d1"
          ></config-metrics-form>
        </v-card-text>
      </v-tab-item>
      <v-tab-item key="d7">
        <v-card-text>
          <config-metrics-form
            :id="id"
            type="d7"
            :values="d7"
          ></config-metrics-form>
        </v-card-text>
      </v-tab-item>
      <v-tab-item key="d30">
        <v-card-text>
          <config-metrics-form
            :id="id"
            type="d30"
            :values="d30"
          ></config-metrics-form>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import ConfigMetricsForm from "@/views/pages/admin/report-alert/components/ConfigMetricsForm.vue";

export default {
  name: "InlineMetrics",
  components: {
    ConfigMetricsForm,
  },
  data() {
    return {
      // it's important to set the initial value to null
      // otherwise the first tab will be selected by default
      // and the @change event won't be triggered
      tab: null,
      values: {},
      d1: {},
      d7: {},
      d30: {},
      keys: ["d1", "d7", "d30"],
    };
  },
  methods: {
    loadData(e) {
      this.$store.dispatch("alerts/setRangeType", this.keys[e]);
      this[this.keys[e]] = this.globalAlertConfig[this.id][this.keys[e]];
    },
  },
  computed: {
    id() {
      return this.$store.getters["alerts/getCurrentId"];
    },
    rangeType() {
      return this.$store.getters["alerts/getRangeType"];
    },
    globalAlertConfig() {
      return this.$store.getters["alerts/getGlobalAlertConfig"];
    },
  },
  watch: {
    rangeType() {
      this.tab = this.keys.indexOf(this.rangeType);
    },
    id() {
      this.loadData(this.tab);
    },
    globalAlertConfig() {
      for (const key of this.keys) {
        this[key] = this.globalAlertConfig[this.id][key];
      }
    },
  },
};
</script>

<style scoped></style>
